import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Page from '../components/core/page';
import Opener from '../components/common/opener';
import Closer from '../components/common/closer';
import FaceCard from '../components/pages/team/face-card';

import darrenFace from '../images/faces/darren.png';
import marcFace from '../images/faces/marc.png';
import adamFace from '../images/faces/adam.png';
import aaronFace from '../images/faces/aaron.png';
import emmaFace from '../images/faces/emma.png';

import darrenDoodle from '../images/faces/darren-doodle.svg';
import marcDoodle from '../images/faces/marc-doodle.svg';
import adamDoodle from '../images/faces/adam-doodle.svg';
import aaronDoodle from '../images/faces/aaron-doodle.svg';
import emmaDoodle from '../images/faces/emma-doodle.svg';

import darrenGif from '../images/faces/darren_2.gif';
import marcGif from '../images/faces/marc_2.gif';
import adamGif from '../images/faces/adam_2.gif';
import aaronGif from '../images/faces/aaron_2.gif';
import emmaGif from '../images/faces/emma_2.gif';

const FaceSection = styled.section`
	background-color: ${(p) => p.theme.color.ghost};
	padding: 2rem 0;
`;

const StyledFaces = styled.div`
	max-width: 64rem;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

export default () => (
	<Page
		title="Team"
		desc="5fold is a group of five partners and a growing staff of interdisciplinary talent crafting creative solutions for clients."
	>
		<Opener title="team" subtitle="BEHIND THE KEYBOARDS.">
			<p>
				5fold is a group of five partners and a growing staff of
				interdisciplinary talent crafting creative solutions for clients.
				Doodlers, coders, writers, planners, number crunchers and jingle
				writers, all working together under our mandate: The highest quality
				work, the highest quality customer service, and as much fun as possible.
			</p>
		</Opener>
		<FaceSection>
			<StyledFaces>
				<FaceCard
					face={darrenFace}
					overlay={darrenDoodle}
					gif={darrenGif}
					name="DARREN BURLEY"
					title="President"
					blurb="A serial entrepreneur, Darren has started and grown 5 businesses since the age of 16. He’s a foodie, a traveller, a sports fan and a family man who’s happiest spending time with his wife, two beautiful children and a glass of wine."
				/>
				<FaceCard
					face={marcFace}
					overlay={marcDoodle}
					gif={marcGif}
					name="MARC HOLMES"
					title="Business Development"
					blurb="Marc is a master of diagnosing client needs and prescribing the right digital solutions. He loves golf, baseball, and falling down nearly endless youtube rabbit holes in search of truth, meaning, and how Judge Judy became a thing."
				/>
				<FaceCard
					face={adamFace}
					overlay={adamDoodle}
					gif={adamGif}
					name="ADAM K. JOHNS"
					title="Creative Director"
					blurb="Adam is a creative director, designer, illustrator and a veteran of the creative industry since back when Photoshop came on floppy discs. When he’s not at the office, he’s home with his wife and two beautiful sons, filling up his iPad with drawings of them."
				/>
				<FaceCard
					face={aaronFace}
					overlay={aaronDoodle}
					gif={aaronGif}
					name="AARON McMURTER"
					title="Director of Development"
					blurb="Aaron is our Director of Development and we don’t understand 96% of what he does. Drinking Coke is the other 4%. Not only is he a top-notch developer, but he can hold his own against any designer in a typography debate."
				/>
				<FaceCard
					face={emmaFace}
					overlay={emmaDoodle}
					gif={emmaGif}
					name="EMMA DeBUONO"
					title="Director of Client Services"
					blurb="Emma has over ten years of experience in client services. She uses her bafflingly positive attitude and charm to build strong, long-lasting client relationships while her superhuman project management skills preserve the sanity of designers and developers alike."
				/>
			</StyledFaces>
		</FaceSection>
		<Closer>
			<h2>
				Our people are the best and we want more.
				<br />
				<Link to="/contact">Contact us</Link> if you think you're a fit.
			</h2>
		</Closer>
	</Page>
);
