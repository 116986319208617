import React from 'react';

import StyledFaceCard from './style';

export default ({ face, overlay, name, blurb, gif, title }) => (
	<StyledFaceCard>
		<div className="imgWrap">
			<img src={gif} alt={name} className="anim" />
			<img src={face} alt={name} className="face" />
			<img src={overlay} alt={`${name} illustrations`} className="overlay" />
		</div>
		<div className="body">
			<h3>{name}</h3>
			<h4>{title}</h4>
			<p>{blurb}</p>
		</div>
	</StyledFaceCard>
);
